@import '~tippy.js/dist/tippy.css';

#theme-page-search-pane {
  margin-top: -70px;
  position: relative;
  z-index: 1;
  
  .app-minitools-search-container {
    padding: 50px 40px;

    input::-webkit-input-placeholder {
      color: #fff;
    }
  }
}

.app-minitools-sidebar {
  position:fixed;
  top:240px;
  right:0px;
  z-index: 1200;
  width: 50px;
  height:255px;
  padding:6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;

  @media (max-width: 768px) {
    width: 80vw;
    height: 50px;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  button {
    border:none;
    padding: 0.4rem;
    background-color: transparent;
  }

  .sm-section {
    margin: 6px 6px 0;
    padding-top: 8px;
    border-top: 1px white solid;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      padding: 0.4rem;
    }

    @media (max-width: 768px) {
      flex-direction: row;
      margin: 0;
      padding-top: 0;
      border-top: none;
    }
  }  
}

.app-minitools-sidebar-offcanvas {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: #333;
  color:#FFF;
  padding-right: 50px;

  &.offcanvas-end {
    top: 240px;
    height: 325px;
    border-bottom-left-radius: 24px!important;
    border-top-left-radius: 24px!important;
    right: 0;
    bottom:auto;
    transform: translateX(100%);

    @media (max-width: 768px) {
      top: auto;
      bottom: 0;
      left: 50%;
      right: auto;
      width: 80vw;
      transform: translateX(-50%) !important;
      border-radius: 0;
      border-top-right-radius: 24px!important;
      border-top-left-radius: 24px!important;
      padding-right: 0;
      padding-bottom: 50px;

      &.showing, &.show:not(.hiding) {
        transform: translateX(-50%) translateY(0%) !important;
      }
    }

    
  }

  .offcanvas-body {
    ul, ol {
      list-style: none;
      padding-left: 0px;
    }

    a:not(.btn) {
      color: #FFF !important;
      text-decoration: underline;
    }
  }
}

.offcanvas-backdrop.show {
  opacity: 0.0;
  z-index: 999;
}

@media (max-width: 768px) {
  #CookiebotWidget {
    display: none !important;
  }

  #theme-to-top {
    bottom: 65px !important;
  }
}